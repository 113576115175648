//to add different comparators for the ag-grid BaseTable component

export function dateComparator(valueA, valueB, nodeA, nodeB, isDescending) {
  if (valueA === null || valueA === undefined || valueA == '') valueA = '-';
  if (valueB === null || valueB === undefined || valueB == '') valueB = '-';
  if (valueA !== '-' && valueB === '-') {
    return isDescending ? 1 : -1;
  } else if (valueA === '-' && valueB !== '-') {
    return isDescending ? -1 : 1;
  } else if (valueA == valueB) {
    return isDescending ? -1 : 1;
  } else if (new Date(valueA) > new Date(valueB)) {
    return 1;
  } else {
    return new Date(valueA) - new Date(valueB);
  }
}

export function numberComparator(valueA, valueB, nodeA, nodeB, isDescending) {
  if ((valueA === null || valueA === undefined || isNaN(valueA)) && isDescending) {
    return -1;
  } else if ((valueA === null || valueA === undefined || isNaN(valueA)) && !isDescending) {
    return 1;
  }

  if ((valueB === null || valueB === undefined || isNaN(valueB)) && isDescending) {
    return 1;
  } else if ((valueB === null || valueB === undefined || isNaN(valueB)) && !isDescending) {
    return -1;
  }

  return valueA - valueB;
}

export function textComparator(valueA, valueB, nodeA, nodeB, isDescending) {
  if (valueA === null || valueA === undefined || valueA == '') valueA = '-';
  if (valueB === null || valueB === undefined || valueB == '') valueB = '-';
  if (valueA !== '-' && valueB === '-') {
    return isDescending ? 1 : -1;
  } else if (valueA === '-' && valueB !== '-') {
    return isDescending ? -1 : 1;
  } else if (valueA == valueB) {
    return isDescending ? -1 : 1;
  } else if (valueA > valueB) {
    return 1;
  } else if (valueA < valueB) {
    return -1;
  }
}

export function sectorComparator(valueA, valueB, nodeA, nodeB, isDescending) {
  valueA = valueA?.map(i => i.text).join(', ');
  valueB = valueB?.map(i => i.text).join(', ');
  return textComparator(valueA, valueB, nodeA, nodeB, isDescending);
}
